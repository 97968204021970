.footer {
    width: 100%;
    color: #eee;
    padding: 2rem;
    padding-top: 12rem;
}

.footer .container {
    max-width: 1240px;
    margin: auto;
    text-align: center;
}

.bottom {
    text-align: center;
}

.line {
    margin-bottom: 1rem;
}





