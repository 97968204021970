
.header {
    position: fixed;
    width: 100%;
    z-index: 1;
    overflow: hidden;
}

/* ---Sidebar css */
.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}

.sidebar{
    padding-top: 80px;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 1;
    transition: 0.5s;
}
.sidebar.active{
    right: 0;
}

.sidebar-overlay{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}

.sd-body ul li .sd-link{
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}