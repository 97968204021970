.hero {
    width: 100%,;
    height: 100vh;
}

.heroImg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.contents {
    position: absolute;
    top:0;
    display: block;
    width: 100%;
    max-width: 450px;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
}


.contentCard {
    position: absolute;
    top: 85%; right: 50%;
    transform: translate(50%,-50%);
}

.textStroke { 
    text-shadow:
    1px 1px 0 #000,
    -1px 1px 0 #000,
    -1px -1px 0 #000,
    1px -1px 0 #000;
}

@media screen and (min-width: 768px) {
    .textStroke { 
        text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    }
  }

  @media screen and (min-width: 1024px) {
    .textStroke { 
        text-shadow:
        1px 1px 0 #000,
        -1px 1px 0 #000,
        -1px -1px 0 #000,
        1px -1px 0 #000;
    }
  }

.textStrokeSmall { 
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}


