@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background-image: url('./components/images/background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.deeventcolor {
  color: #ec1d25;
}

.deeventcolorbg {
  background-color: #ec1d25;
}

th, td { min-width: 200px; }

.container img {
  object-fit: cover;
  margin-left: auto; 
  margin-right: auto; 
} 

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body {

  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.regbg {
    /* Or, use the background shortcut. */
    background: url('./components/images/AIA\ Website\ BG.png') center no-repeat;
    background-size:contain;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.xxs {
  font-size: 0.625rem; /* 12px */
line-height: 0.75rem; /* 16px */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.special {
  font-family: 'Pacifico', cursive;
}

.nicer {
  font-family: 'Abril Fatface', cursive;
}

.line {
  display: block;
  width: 50px;
  height: 5px;
  margin: 10px auto;
}

.sttelemedia {
  font-family: 'Lato', sans-serif;
}

.screen {
  font-family: 'Abril Fatface', cursive;
  font-family: 'Lato', sans-serif;
  font-family: 'Pacifico', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
}
